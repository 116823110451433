<div class="full-height-bs" fxLayout="column">

    <div fxFlex="none">
        <h2 mat-dialog-title>APPLICATION BROWSE</h2>
        <h3 class="sw-upper">Delete Row from {{tableName}} table</h3>
    </div>

    <div fxFlex *ngIf="isWaitingForDependencies()">
        <spinner text="WAITING FOR ROW DEPENDENCY DATA"></spinner>
    </div>

    <div fxFlex *ngIf="isDeleting()">
        <spinner text="DELETING ITEMS"></spinner>
    </div>

    <div fxFlex mat-dialog-content *ngIf="isDependencyDataReady()">

        <mat-tree  [dataSource]="dataSource" [treeControl]="treeControl" class="sw-tree full-width-bs full-height-bs">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <div class="sw-table-name" [ngClass]='node.isDeletion?"sw-del":"sw-upd"'>
                    <span>{{node.tableName}}</span>
                </div>
                <div class="sw-reg-col" [ngClass]='{"sw-pk":node.isPk(c)}' *ngFor="let c of node.fkParentFilter()">
                    <span>{{c.value}}</span>
                </div>
            </mat-tree-node> 
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle>
                <div class="mat-tree-node">
                    <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <div class="sw-table-name" [ngClass]='node.isDeletion?"sw-del":"sw-upd"'>
                        <span>{{node.tableName}}</span>
                    </div>
                    <div class="sw-reg-col" [ngClass]='{"sw-pk":node.isPk(c)}' *ngFor="let c of node.fkParentFilter()">
                        <span>{{c.value}}</span>
                    </div>
                </div>  
                <div [class.sw-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>

    </div>

    <div fxFlex="none" class="sw-pad-bottom">
        <div mat-dialog-actions fxLayout='row' fxLayoutGap="10px" >
            <div fxFlex></div>
            <div fxFlex="none">
                <button mat-raised-button color="primary" (click)='apply()' [disabled]="!isValid()">
                    <mat-icon>done</mat-icon>
                    <span>Accept</span>
                </button>
            </div>
            <div fxFlex="none">
                <button mat-raised-button (click)='cancelDialog()'>
                    <mat-icon>cancel</mat-icon>
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>

</div>