export class JobTypeElements {
    constructor(main: number, test?: number)
    {
        this.main = main;
        if (test)
            this.test = test;
    }

    public main: number;
    public test:number=0;
}