import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { ApplicationService, MsiService, ProjectService } from '../api/SVC';
import { ConversionService } from '../api/SVC';
import { UtilService } from '../svc/utilService';
import { Constants } from '../api/Constants';
import { ApplicationRelationshipType } from '../api/Enum';
import { OutputItemInfoApp } from '../model/OutputItemInfoApp';
import { IssueListHelper } from '../shared-helper/issue-list-helper';
import { PlatformService } from '../svc/platformService';
import { EventService } from '../svc/eventService';
import { ApplicationBrowser } from '../model/applicationBrowser';
import { StateHelperService } from './stateHelperService';
import { DataContextProvider } from './DataContextProvider';
import { ApplicationHelper } from './applicationHelper';

import * as Enums from '../api/Enum';
import * as CRS from '../api/CRS';
import * as IRQ from '../api/IRQ';

@Injectable({
    providedIn: 'root'
})
export class CurrentApplicationService {

    constructor( 
        private applicationService: ApplicationService,
        private conversionService: ConversionService,
        private msiService: MsiService,
        private eventService: EventService,
        private platformService: PlatformService,
        private utilService: UtilService,
        private injector: Injector,
        private stateHelperService: StateHelperService
    ) { 
        this.dataContext = new DataContextProvider<CRS.ResponseWrapper<CRS.RSGetApplication>>(
            "Application",
            this.utilService,
            this.stateHelperService, 
            ()=>{return this.stateHelperService.details.applicationId},
            ()=>this.preLoad(),
            (id,refresh)=>{return this.mainLoad(id, refresh)},
            null,
            (data)=>this.partialRefresh(data));

        this.dataContext.setTimerInterval(Constants.POLLING_APPLICATIONVIEW_INTVL);
        this.dataContext.onLoaded().subscribe(()=>this.mainLoaded());
        this.dataContext.onRefreshed().subscribe(()=>this.checkFixingDone());

        this._onFixingDone=new Subject<void>();
        this._onClickerSaved=new Subject<void>();
        this._applicationHelper = new ApplicationHelper(this.applicationService);

        this.outputTypes = [
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_APPCOMPATREPORT, Enums.OutptType.PDF,  this.getAppCompatWarning),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_REPACKAGE, Enums.OutptType.Zip),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_VIRTUALISE, Enums.OutptType.Zip),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_MSIX, Enums.OutptType.Zip ),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_FIX, Enums.OutptType.Zip),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_SECURITYREPORT, Enums.OutptType.Html),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_RESPONSETRANSFORM, Enums.OutptType.Zip),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_EXTRACTTRANSFORM, Enums.OutptType.Zip ),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_MSIGATHER, Enums.OutptType.Zip),
            new OutputItemInfoApp(this, this.utilService, Constants.JOBTYPE_EVALUATIONREPORT, Enums.OutptType.PDF)
        ];
        this.platformService.onPlatformChanged().subscribe((n)=> {
            this._currentPlatformNo=n;
        })
        this._appBrowse =new ApplicationBrowser(this, this.injector);
    }

    public dataContext: DataContextProvider<CRS.ResponseWrapper<CRS.RSGetApplication>>;

    public get dataItem(): CRS.RSGetApplication {
        return this.dataContext.item().data;
    }

    public onFixingDone() {
        return this._onFixingDone.asObservable();
    }

    public onClickerSaved() {
        return this._onClickerSaved.asObservable();
    }

    private _onFixingDone : Subject<void>;
    private _onClickerSaved: Subject<void>;
    private _applicationHelper: ApplicationHelper;

    private preLoad(): void {
        this._documentationFiles=null;
        this._issues=null;
        this._issueSummarisationsDictionary=null;
        this._issuesPlatformNumber=0;
        this._siblingInstallers=null;
        this._previousVirtualisationArguments=null;
        this._currentPlatformNo=this.platformService.getCurrent();
    }

    private mainLoad(id:string, refreshOnly:boolean) : Promise<CRS.ResponseWrapper<CRS.RSGetApplication>> {
        this._currentPlatformNo=this.platformService.getCurrent();
        return this.applicationService.getApplication(id,refreshOnly)
    }

    private mainLoaded() {
        this._fixingInProgress= (this.dataContext.isReady && this.dataItem.fixingJobInfo && this.dataItem.fixingJobInfo.status == 1);
    }

    private partialRefresh(data: CRS.ResponseWrapper<CRS.RSGetApplication>) {
        data=data;
    }

    public canEditMetaData(): boolean {
        return this.dataContext.isReady 
                && (this.dataItem.isProjectOwner || this.dataItem.isProjectContributor || this.utilService.privileges?.admin)
                && this.utilService.privileges?.canUpdateApplicationMetaData;
    }

    public canEditWorkflowDetails(): boolean {
        return this.dataContext.isReady 
                && (this.dataItem.isProjectOwner || this.dataItem.isProjectContributor || this.utilService.privileges?.admin)
                && this.utilService.privileges?.canUpdateApplicationWorkflowDetails;
    }

    public canEditCoreData(): boolean {
        return this.dataContext.isReady 
                && (this.dataItem.isProjectOwner || this.dataItem.isProjectContributor || this.utilService.privileges?.admin)
                && this.utilService.privileges?.canEditApplicationCoreData;
    }

    public canPublish() : boolean {
        return this.dataContext.isReady && this.dataItem.hasValidAppId;
    }

    public setNoRepack() {
        this.dataItem.hasBeenAssessed=false;
        this.dataItem.repackageStatus=-1;
        this.browser.canViewPackageRefresh();
    }

    // public hasBeenRepackaged() : boolean {
    //     return this.dataContext.isReady && this.dataContext.item().noAssess && this.dataContext.item().hasBeenAssessed;
    // }

    public hasBeenSuccessfullyRepackaged() : boolean {
        return this.dataContext.isReady && this.dataItem.repackageStatus == 4;
    }

    public delete() : void {
        this.applicationService.deleteApplication(this.dataItem.projectId, this.dataContext.id).then((s)=>{
            this.eventService.__projectDetailsUpdateRequestTrigger();
        });
    }

    public runAppCompatReport(): void {
        this.applicationService.runReport(this.dataItem.projectId, this.dataContext.id, "APPCOMPAT", 0, this.utilService.getEmptyGuid());
    }

    public updateAppId(): Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=>{
            this.applicationService.updateAppId(this.dataContext.id).then((ret)=>{
                if (ret) {
                    this.dataContext.refreshCurrent();
                    resolve(true);
                }
            }, (ex)=>{
                reject(ex);
            });
        });
    }
    
    public get browser() : ApplicationBrowser {
        return this._appBrowse;
    }

    private _currentPlatformNo:number;
    private _appBrowse : ApplicationBrowser=null;

    public outputTypes : Array<OutputItemInfoApp> ;

    public applyFixes(platform:number, issuesToFix: Array<string>) {
        this.dataItem.fixingJobInfo = new CRS.RSFixingJobInfo();
        this.dataItem.fixingJobInfo.status = 1;
        this.dataItem.fixingJobInfo.progressPercentage = 0;
        this.dataItem.fixingJobInfo.fixDateExpression = "Running";
        this.dataItem.fixingJobInfo.errors = [];
        this.applicationService.applyFixes(
            this.dataItem.projectId, 
            this.dataContext.id, 
            platform,
            issuesToFix
        );
        this.dataContext.refreshCurrent();
        this._fixingInProgress=true;
    }

    private _fixingInProgress: boolean=false;
    private checkFixingDone() {
        if (this.dataContext.isReady) {
            var fip = (this.dataContext.isReady  && this.dataItem.fixingJobInfo && this.dataItem.fixingJobInfo.status <= 1);
            if (this._fixingInProgress) {
                if (!fip) {
                    this._fixingInProgress=false;
                    this._onFixingDone.next();
                }
            }
            else if (fip) {
                this._fixingInProgress = true;
            }
        }
    }

    public ignoreIssues(issues: Array<string>) : void {
        this.applicationService.ignoreIssues(this.dataContext.id, issues).then((ret)=>{
            if (ret) {
                this.dataContext.refreshCurrent().then((v)=>{
                    this._onFixingDone.next();
                });
            }
        });
    }

    public saveClickerScript(script: Array<string>) : void {
        this.applicationService.saveClickerScript(this.dataContext.id, script).then((ret)=>{
            if (ret) {
                this.dataContext.refreshCurrent().then((v)=>{
                    this._onClickerSaved.next();
                });
            }
        });
    }

    public setApplicationSourcePath(sourcePath:string) : Promise<CRS.ResponseWrapper<any>> {
        return this.applicationService.setApplicationSourcePath(this.dataContext.id, sourcePath);
    }

    public importMsiExtracts(conversionJobId: string, toImport : Array<string>) : Promise<boolean> {
        return this.applicationService.importExtractedApplication(conversionJobId, this.dataItem.id, toImport);
    }

    public updateQaValue(sectionId:string, rowIndex:number, columnIndex:number, value:string) : Promise<boolean> {
        return this.applicationService.updateQaValue(this.dataContext.id, sectionId, rowIndex, columnIndex, value);
    }

    public deleteCompanionFile(fileId: string) : Promise<boolean> {
        return this.applicationService.deleteCompanionFile(this.dataContext.id, fileId)
    }

    public updatePackageKeySet(keys: Array<IRQ.IRQKeyItem>) : Promise<boolean> {
        return this.msiService.msiUpdateKeySet(this.dataContext.id, 0, keys);
    }

    public undoPackageUpdates(stepCount:number) : Promise<boolean> {
        return this.msiService.msiUndoPackageUpdates(this.dataContext.id, 0, stepCount);
    }

    public get isLoadedSuccessfully() : boolean {
        return this.dataContext.isReady && this.dataContext.item() && this.dataItem.status == Constants.STATUS_SUCCESS;
    }

    public get isLoadedAndPending() : boolean {
        return this.dataContext.isReady && this.dataContext.item() && this.dataItem.status == Constants.STATUS_PENDING;
    }

    public get isLoadInProgress() : boolean {
        return this.dataContext.isReady 
        && this.dataItem 
        && this.dataItem.status <= Constants.STATUS_INPROGRESS;
    }    

    public get availableOutputs() : number {
        return this.outputTypes.filter(x=>x.getStatus()>=0).length;
    }

    public getAppCompatWarning() : string {
        if (this.dataContext.isReady && this.dataItem.isCompatibilityReportSynchronised)
            return null
        else 
            return "platform has changed since report was generated";
    }

    public hasFixTransform(platformNumber?:number): boolean {
        var pn = (platformNumber) ? platformNumber : this._currentPlatformNo;
        return this.dataContext.isReady && this.dataItem.platformsWithFixTransform.indexOf(pn) != -1;
    }

    private _documentationFiles: CRS.RSGetApplicationDocumentationFiles=null;
    public getDocumentationFiles() : Promise<CRS.RSGetApplicationDocumentationFiles> {

        if (!this.dataContext.isReady || !this.dataItem.isFromMappedDrive) {
            return new Promise<CRS.RSGetApplicationDocumentationFiles>((r,x)=> {
                r(null);
            });
        };
    
        return this._applicationHelper.getDocumentationFiles(this.dataContext.id);
    }

    public getIssuesDirect() : IssueListHelper {
        return this._issues;
    }
    
    private _issues: IssueListHelper=null;
    private _issueSummarisationsDictionary: { [platformNo:string]: CRS.RSIssueSummarisation };
    private _issuesPlatformNumber: number=0;
    public getIssues(platformNumber:number, forceRefresh?: boolean) : Promise<IssueListHelper> {

        return new Promise<IssueListHelper>((resolve, reject) => {

            if (!this.dataContext.isReady)
                resolve(null);

            if (!this._issues || forceRefresh)
            {
                this.applicationService.getApplicationIssues( this.dataContext.id, forceRefresh?false:true).then((response) => {
                    this._issues = new IssueListHelper(response.data.issues, response.data.assessmentGroups);
                    this._issues.ReCalcCustom(platformNumber, true);
                    this._issuesPlatformNumber=platformNumber;
                    this._issueSummarisationsDictionary =response.data.issueSummarisationsDictionary;
                    resolve(this._issues);
                }, (err) => {
                    reject(err);
                });
            }
            else {
                if (platformNumber != this._issuesPlatformNumber) {
                    this._issues.ReCalcCustom(platformNumber, true);
                    this._issuesPlatformNumber=platformNumber;
                }
                resolve(this._issues);
            }
        });
    }

    public getHistory(): Promise<CRS.ResponseWrapper<CRS.RSGetApplicationActivity>> {

        return new Promise<CRS.ResponseWrapper<CRS.RSGetApplicationActivity>>((resolve, reject)=> {

            this.applicationService.getApplicationActivity(this.dataContext.id).then((response) => {
                resolve(response)
            },()=> {
                console.error("history couldn\'t be loaded");
                reject();
            });
        });
    }

    public getQAChecksheet() : Promise<CRS.RSGetApplicationQaChecksheet> {

        return new Promise<CRS.RSGetApplicationQaChecksheet>((resolve, reject)=> {

            this.applicationService.getApplicationQaChecksheet(this.dataContext.id).then((response) => {
                resolve(response.data)
            },()=> {
                console.error("history couldn\'t be loaded");
                reject();
            });
        });

    }

    private _siblingInstallers=null;
    private _previousVirtualisationArguments=null;
    public getSiblingInstallers() : Promise<CRS.RSGetApplicationSiblingInstallers> {

        return new Promise<CRS.RSGetApplicationSiblingInstallers>((resolve, reject)=> {

            if (this._siblingInstallers) {
                resolve(this._siblingInstallers);
            }
            else {
                this.applicationService.getApplicationSiblingInstallers(this.dataContext.id).then((response)=> {
                    this._siblingInstallers = response.data;
                    if (response.data.hasBeenVirtualised)
                        this._previousVirtualisationArguments = response.data.arguments;
                    resolve(this._siblingInstallers);
                }, ()=> {
                    console.error('Sibling installer list couldnt be loaded');
                    reject();
                });
            }
        });
    }

    public findActionById(id:string) : CRS.RSAction {
        if (this.dataContext.isReady)
            return this.findActionByProp("id", id, this.dataItem.actions);
        else
            return null;
    }

    public findActionByAction(action:string) : CRS.RSAction {
        if (this.dataContext.isReady)
            return this.findActionByProp("action", action, this.dataItem.actions);
        else
            return null;
    }

    public findActionByBaseId(actionId:string) : CRS.RSAction {
        if (this.dataContext.isReady) {
            var id = (actionId.endsWith(this.stateHelperService.details.applicationIdCompressed)) ? actionId: `${actionId}${Constants.CONV_ACTION_DELIM}${this.stateHelperService.details.applicationIdCompressed}`;
            return this.findActionByProp("baseId", id, this.dataItem.actions);
        }
        else
            return null;
    }

    private findActionByProp(prop:string, id:string, actions: Array<CRS.RSAction>):CRS.RSAction {
        if (!actions)
            return null;
        var mri = actions.filter(x=>x[prop] == id);
        if (mri.length == 1)
            return mri[0];
        let ret:CRS.RSAction=null;
        actions.forEach(x=> {
            let act = this.findActionByProp(prop, id, x.children);
            if (act!=null)
                ret= act;
        });
        return ret;
    }

    public resetXCheckJob(jobId: string) : Promise<boolean> {
        return new Promise<boolean>((resolve,reject) => {

            var item = this.dataItem;
            this.conversionService.resetApplicationAction(jobId).then((response)=> {
                resolve( true);
            }, (err)=> {
                reject(err);
            });
        });
    }

    public updatePatchProductApplication(productId:string, applicationId:string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=>{
            this.dataItem.patchParts.forEach(i=>{
                if (i.productCode != productId)
                    i.applicationId=i.applicationName=null;
            });
           this.applicationService.updatePatchProductApplication(this.dataItem.id, productId, applicationId).then((response)=>{
                this.dataItem.patchCanConfirm=true;
                resolve(true);
           }, (err)=>{
                reject(err);
           }); 
        });
    }

    public confirmPatchApplication() : Promise<boolean> {
        this.dataItem.patchCanConfirm=false;
        return new Promise<boolean>((resolve, reject)=>{
           this.applicationService.confirmPatchApplication(this.dataItem.id).then((response)=>{
                resolve(true);
           }, (err)=>{
                reject(err);
           }); 
        });
    }

    public getIssueSummarisations(platformNo: number) : Promise<CRS.RSIssueSummarisation> {

        return new Promise<CRS.RSIssueSummarisation>((resolve, reject) => {

            if (!this.dataContext.isReady)
                resolve(null);

            this.getIssues(platformNo).then((data)=> {
                resolve( this._issueSummarisationsDictionary[platformNo]);
            }, (err)=> {
                reject(err);
            });

        });
    }

    public getApplicationsAtPath() : Promise<Array<CRS.RSApplication>> {

        return new Promise<Array<CRS.RSApplication>>((resolve,reject) => {

            var item = this.dataItem;
            this.applicationService .getApplicationsAtPath(item.projectId, item.path).then((response)=> {
                resolve( response.data.items.filter(x=>x.id != item.id));
            }, (err)=> {
                reject(err);
            });
        });
    }

    public registerInstallDependency(relationshipType: ApplicationRelationshipType, expression:string) {
        this.applicationService.registerApplicationInstallDependency(this.dataContext.id, relationshipType, 0, expression).then((response)=>{
            var raid = new CRS.RSApplicationInstallDependency();
            raid.index = response.data.createdItem.index;
            raid.relationshipType = response.data.createdItem.relationshipType;
            raid.relationshipTypeName = Enums.ApplicationRelationshipType[relationshipType];
            raid.relationshipSubType= 0;
            raid.dependentApplicationId = response.data.createdItem.dependentApplicationId;
            raid.dependentApplicationDisplayName = response.data.createdItem.dependentApplicationDisplayName;
            raid.locatorExpression = response.data.createdItem.locatorExpression;
            raid.locatorExpressionDisplayName = response.data.createdItem.locatorExpressionDisplayName;
            this.dataItem.installDependencies.push(raid);
        });
    }

    public moveUpInstallDependency(dep: CRS.RSApplicationInstallDependency) {
        this.applicationService.moveApplicationInstallDependencyOrder(this.dataContext.id, dep.index, -1).then((response)=> {
            var a = this.dataItem.installDependencies.filter(x=>x.index == dep.index);
            var b = this.dataItem.installDependencies.filter(x=>x.index == response.data.switchedIndex);
            var tmp = dep.index;
            a.forEach(x=>x.index = response.data.switchedIndex);
            b.forEach(x=>x.index = tmp);
        });
      }
    
    public moveDownInstallDependency(dep: CRS.RSApplicationInstallDependency) {
        this.applicationService.moveApplicationInstallDependencyOrder(this.dataContext.id, dep.index, 1).then((response)=> {
            var a = this.dataItem.installDependencies.filter(x=>x.index == dep.index);
            var b = this.dataItem.installDependencies.filter(x=>x.index == response.data.switchedIndex);
            var tmp = dep.index;
            a.forEach(x=>x.index = response.data.switchedIndex);
            b.forEach(x=>x.index = tmp);
        });
      }
    
    public deleteInstallDependency(dep:CRS.RSApplicationInstallDependency) {
        this.applicationService.deleteApplicationInstallDependency(this.dataContext.id, dep.index).then((response)=> {
            var idx = this.dataItem.installDependencies.indexOf(dep);
            if (idx != -1)
                this.dataItem.installDependencies.splice(idx, 1);
        });
    }

    public isUpdating: boolean = false;

    public persistArguments(argumentType:number) {
        this.isUpdating=true;
        var v = (argumentType == Constants.ARGUMENT_UPDATE_TYPE_NORMAL) ? this.dataItem.arguments : this.dataItem.argumentsSilent;
        this.applicationService.setArguments(this.dataContext.id, argumentType, v)
    }

    public persistWorkflowStage() {
        this.isUpdating=true;
        this.applicationService.setWorkflowStage(this.dataContext.id, this.dataItem.workflowStage).then((ret)=>{
            this.isUpdating=false;
        });
    }

    public persistWorkflowStageState() {
        this.isUpdating=true;
        this.applicationService.setWorkflowStageState(this.dataContext.id, this.dataItem.workflowStageState).then((ret)=>{
            this.isUpdating=false;
        });
    }

    public persistPriority() {
        this.isUpdating=true;
        this.applicationService.setPriority(this.dataContext.id, this.dataItem.priority).then((ret)=>{
            this.isUpdating=false;
        });
    }

    public persistAssignee() {
        this.isUpdating=true;
        this.applicationService.setAssignee(this.dataContext.id, this.dataItem.assignee).then((ret)=>{
            this.isUpdating=false;
        });
    }

    public persistManufacturer() {
        this.isUpdating=true;
        this.applicationService.setApplicationManufacturer(this.dataContext.id, this.dataItem.manufacturer).then((ret)=> {
            this.isUpdating=false;
        });
    }

    public persistVendorState() {
        this.isUpdating=true;
        this.applicationService.setApplicationVendorState(this.dataContext.id, this.dataItem.isVendor).then((ret)=> {
            this.isUpdating=false;
        });
    }

    public persistMiddlewareState() {
        this.isUpdating=true;
        this.applicationService.setApplicationMiddlewareState(this.dataContext.id, this.dataItem.isMiddleware).then((ret)=> {
            this.isUpdating=false;
        })
    }

    public persistArchitecture() {
        this.isUpdating=true;
        this.applicationService.setApplicationArchitecture(this.dataContext.id, this.dataItem.architectureName).then((ret)=>{
            this.isUpdating=false;
        })
    }

    public persistFriendlyName() {
        this.isUpdating=true;
        this.applicationService.setApplicationFriendlyName(this.dataContext.id, this.dataItem.friendlyName).then((ret)=> {
            this.isUpdating=false;
        })
    }

    public persistProductName() {
        this.isUpdating=true;
        this.applicationService.setApplicationProductName(this.dataContext.id, this.dataItem.productName).then((ret)=> {
            this.isUpdating=false;
        })
    }

    public persistCompoundVersion() {
        this.isUpdating=true;
        this.applicationService.setApplicationCompoundVersion(this.dataContext.id, this.dataItem.compoundVersion).then((ret)=> {
            this.isUpdating=false;
        });
    }

    public userCanFix() {
        return this.utilService.privileges && this.utilService.privileges.canFix;
    }

    public isFixInProgress() {
        if (this.dataContext.isReady) {
            return this.dataItem.generatedOutputItems.some(x=>x.type == Constants.JOBTYPE_FIX && x.status == Enums.SpineStatus.InProgress);
        } 
        return false;
    }

}