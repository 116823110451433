import { Component } from '@angular/core';
import { MenuService } from '../../svc/menuService';
import { MenuArgs } from '../../model/MenuArgs';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';

@Component({
  selector: 'project-menu',
  templateUrl: './project-menu.html',
  styleUrls: ['../../shared/css/header.scss', '../../shared/css/buttons.scss']
})
export class ProjectMenu {

  constructor(
      private currentProjectService: CurrentProjectService,
      public menuService: MenuService) { 
    }

    public menuItems : Array<MenuArgs> = [
        MenuArgs.create(Constants.MENU_DELETE, 'Delete', 'delete'),
        MenuArgs.create(Constants.MENU_DELETE_PROJECT, 'Delete Project', 'delete'),
        MenuArgs.create(Constants.MENU_FIX, 'Fix Application', 'build'),
        MenuArgs.create(Constants.MENU_IGNORE_ISSUES, 'Ignores Issues', 'rule'),
        MenuArgs.create(Constants.MENU_ADD_APPLICATION, 'Add Application', 'add'),
        MenuArgs.create(Constants.MENU_SET_APPLICATION_SOURCE_PATH, 'Set Application Source Path', 'add'),
        MenuArgs.create(Constants.MENU_ABOUT, "About", "info"),
        MenuArgs.create(Constants.MENU_ADD_APPLICATIONS_FROM_CSV, 'Add Applications From Csv', 'add'),
        MenuArgs.create(Constants.MENU_VIEW_PACKAGE, 'MSI Viewer', 'view_list'),
        MenuArgs.createMulti(Constants.MENU_VIEW_PACKAGE_MULTI, 'View Application', 'view_list'),
        MenuArgs.create(Constants.MENU_UPDATE_APPCOMPAT_REPORT, 'Update Report', 'loop'),
        MenuArgs.create(Constants.MENU_INITIATE_DRIVE_SCAN, 'Scan Drive', "youtube_searched_for"),
        MenuArgs.create(Constants.MENU_INITIATE_DRIVE_SCAN_QUICK, 'Scan Drive (Quick)', "youtube_searched_for"),
        MenuArgs.create(Constants.MENU_QUEUE_IMPORT, 'Queue Import', 'play_circle_outline'),
        MenuArgs.create(Constants.MENU_VIEW_SAS_KEY, 'View SAS Key', 'vpn_key'),
        MenuArgs.create(Constants.MENU_UPDATE_PLATFORM_NAME, 'Update Platform Name', 'mode_edit'),
        MenuArgs.create(Constants.MENU_ADD_SHARE, 'Share Project', 'person_add'),
        MenuArgs.create(Constants.MENU_CLEAR_ENDED_CONVERSIONS, 'Clear', 'clear'),
        MenuArgs.create(Constants.MENU_RESET_APPLICATION_ACTION, 'Clear', 'clear'),
        MenuArgs.create(Constants.MENU_UNPUBLISH, 'Unpublish', 'clear'),
        MenuArgs.create(Constants.MENU_REMOVE_RULE, 'Enable', 'block'),
        MenuArgs.create(Constants.MENU_RESTORE_RULE, 'Disable', 'block'),
        MenuArgs.create(Constants.MENU_DISABLE_ASSESSMENTGROUP, 'Disable', 'block'),
        MenuArgs.create(Constants.MENU_ENABLE_ASSESSMENTGROUP, 'Enable', 'block'),
        MenuArgs.create(Constants.MENU_DISABLE_CHECK, 'Disable', 'block'),
        MenuArgs.create(Constants.MENU_ENABLE_CHECK, 'Enable', 'block'),
        MenuArgs.create(Constants.MENU_CHANGE_PASSWORD, 'Change Password', 'vpn_key'),
        MenuArgs.create(Constants.MENU_VIEW_ACTIVITY, 'View Activity', 'history'),
        MenuArgs.create(Constants.MENU_REMOVE_FAILED_APPS, 'Clear Failures', 'delete_sweep'),
        MenuArgs.create(Constants.MENU_CREATE_DRIVE_LINK, 'Drive Link', 'link'),
        MenuArgs.create(Constants.MENU_EXECUTE_QUERY, 'Execute', 'forward'),
        MenuArgs.create(Constants.MENU_SAVE_CUSTOM_QUERY, 'Save', 'save'),
        MenuArgs.create(Constants.MENU_IMPORT_MSI_EXTRACTS, 'Import MSI Extracts', 'unarchive'),
        MenuArgs.create(Constants.MENU_PUBLISH_PACKAGE, 'Publish Application', 'publish'),
        MenuArgs.create(Constants.MENU_IMPORT_STANDARDS_CHECKS, 'Import Standards Checks', 'import'),
        MenuArgs.create(Constants.MENU_CLEAR_CACHE,'Clear Cache', 'clear'),
        MenuArgs.create(Constants.MENU_REFRESH_METRICS, 'Refresh Metrics', 'refresh'),
        MenuArgs.create(Constants.MENU_REMOTING_RESET, 'Remoting Reset', 'refresh'),
        MenuArgs.create(Constants.MENU_UPDATE_APPID, 'Update AppId', 'refresh'),

        MenuArgs.create(Constants.MENU_CREATE_XCHECK_VIRTUAL_MACHINE, 'Create Virtual Machine', 'add_to_queue'),
        MenuArgs.create(Constants.MENU_CREATE_XCHECK_PATCH_TEST, 'Create Portfolio Test', 'play_circle_outline'),
        MenuArgs.create(Constants.MENU_ADMIN_ASSGRPS_BACK, 'Close', 'arrow_back'),
        MenuArgs.createToggle(Constants.MENU_TOGGLE_SHOW_FIXED_ISSUES, 'Show Fixed Issues', 'Hide Fixed Issues', 'check', 'fixed-issues-visible'),
        MenuArgs.createToggle(Constants.MENU_TOGGLE_SHOW_VMS_PANEL, "Show Virtual Machine Panel", "Hide Virtual Machine Panel", 'check', Constants.LOCALSET_SHOW_VMS_PANEL),
        MenuArgs.createMulti(Constants.MENU_SELECT_CUSTOM_QUERY, 'Select Custom Query', 'dns'),
        MenuArgs.createMulti(Constants.MENU_REPORT_OPTIONS, 'Reports', 'layers')
       
      ];

  public activeSingularFilter = (item: MenuArgs) : boolean => {
     return !item.hasSubMenu && this.menuService.canShow(item.id);
  }

  public activeSubMenuFilter = (item: MenuArgs) : boolean => {
     return item.hasSubMenu && this.menuService.getSubMenu(item.id).length > 0;
  }

  public emptySubMenuFilter = (item: MenuArgs) : boolean => {
     return item.hasSubMenu && this.menuService.getSubMenu(item.id).length == 0;
 }

  public isToggleOn(storage:string) : boolean {
      return localStorage.getItem(storage) =="true";
  }

  public get isInError() : boolean {
    return this.currentProjectService.dataContext.isInError;
  }
}
