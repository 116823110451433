import { Constants } from '../api/Constants';
import { JobTypeElements } from './JobTypeElements';

export class ActionIdParts {

    static parse(jobId:string, isJobId: boolean, appId:string) : ActionIdParts {
        let parts = jobId.split(Constants.CONV_ACTION_DELIM);
        var ret=  new ActionIdParts();

        if (parts.length> 0){

            var appIdPart = parts.indexOf(appId);
            if (appIdPart != -1)
                parts.splice(appIdPart, 1);

            if (parts.length==1 && !isJobId) {
                ret.rootType = parts[0]
                ret.isRoot=true;
            }
            else {
                ret.isRoot=false;
                ret.rootType=null;
                var jte=  (parts.length>1)? this.mnemonicToJobType(parts[1]): null;
                ret.mainJobType =jte?.main;
                ret.conversionType = jte?.test;
                ret.type = (parts.length>0) ? parts[1] : null;
                if (parts.length>1 && this.isXCheckChild(parts[1])) {
                    ret.type = parts[1];
                    ret.conversionType = 14;
                }
                else {
                    var pt2 = (parts.length>2)? this.splitTestSubPart(parts[2]): null;
                    //ret.conversionType= pt2?.tt; /* this is an action type for creating tasks *///
                    ret.conversionSubType=pt2?.st;
                }
            }
            
        }

        return ret;
    }

    private static splitTestSubPart(value:string): any {
        var r= value.match(/([0-9]+)(.+)/);
        return (r)?  { "tt": r[1], "st":r[2] }: null;
    }


    private static mnemonicToJobType(value:string): JobTypeElements {
        switch(value){
            case "DISC": return new JobTypeElements(Constants.JOBTYPE_TEST, Constants.JOBTYPE_DISCOVERY);
            case "T": return new JobTypeElements( Constants.JOBTYPE_TEST);
            case "RTC": return new JobTypeElements( Constants.JOBTYPE_TEST, Constants.JOBTYPE_RESPONSETRANSFORM );
            case "V": return new JobTypeElements( Constants.JOBTYPE_VIRTUALISE);
            case "R": return new JobTypeElements( Constants.JOBTYPE_REPACKAGE);
            case "M": return new JobTypeElements(Constants.JOBTYPE_MSIX);
            case "MM": return new JobTypeElements(Constants.JOBTYPE_TEST,Constants.JOBTYPE_MSIMODIFY);
            case "L": return new JobTypeElements(Constants.JOBTYPE_LIQUIDWARE);
            case "P": return new JobTypeElements(Constants.JOBTYPE_PUBLISH);
            case "SHT": 
            case "X": return new JobTypeElements(Constants.JOBTYPE_XCHECK);
            default: return null;
        }
    }

    public isRoot: boolean;
    public rootType: string;
    public type:string;
    public conversionType:number;
    public conversionSubType:string;
    public mainJobType:number;

    public get conversionSubTypeExpanded(): string {
        switch(this.conversionSubType){
            case "B":return "Basic";
            case "F":return "Fixed";
            case "R":return "Runtime";
            default:return this.conversionSubType;
        }
    }

    private static _xcheckChildren =[
        Constants.ACTION_XCHECK_SHT,
        Constants.ACTION_XCHECK_EVL,
        Constants.ACTION_XCHECK_DSK,
        Constants.ACTION_XCHECK_SVC,
        Constants.ACTION_XCHECK_COM,
        Constants.ACTION_XCHECK_DCOM,
        Constants.ACTION_XCHECK_PRT,
        Constants.ACTION_XCHECK_FIR,
        Constants.ACTION_XCHECK_ENV,
        Constants.ACTION_XCHECK_SCHED,
        Constants.ACTION_XCHECK_COMPAT,
        Constants.ACTION_XCHECK_DEFEX
    ];
    public static isXCheckChild(id:string) : boolean {
        if (!id)
            return false;
        return this._xcheckChildren.indexOf(id.toLocaleUpperCase())>=0;

    }

    public getJobCreateHeading() :string {
        switch(this.conversionType)
        {
            case 1: return "REPACKAGE APPLICATION";
            case 2: return "VIRTUALISE APPLICATION";
            case 20: return "CREATE MSIX PACKAGE";
            case 21: return "CREATE INTUNE PACKAGE";
            case 23: return "CREATE LIQUIDWARE PACKAGE";
            default:
                return null;
        }    
    }
}