import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { CommandCollection } from 'src/app/commands/command-collection';
import { AddApplicationData } from 'src/app/model/AddApplicationData';
import { CurrentProjectService } from 'src/app/svc/currentProjectService';

@Component({
    selector: 'add-application-dialog',
    templateUrl: 'add-application-dialog.html'
})
export class AddApplicationDialog {

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private currentProject: CurrentProjectService
    ) {
        this.stateCtrl = new FormControl();
        this.stateCtrl.valueChanges.subscribe(val => {
            this.filterStates(val);
        });
    }

    filterStates(name: string) {
        if (name) {
            this.currentProject.searchPackageSourceFolders(name).then((d)=>{
                this.filteredStates = new Observable(observer => {
                    observer.next(d.data.items);
                });     
            });
        }
    }

    public refreshRelatedTransforms() {
        if (!this.details.sourceMediaPath) {
            this.details.transforms=null;
            }
        else {
            this.currentProject.getProjectPackageSourceTransforms(this.details.sourceMediaPath).then((d)=>{
                this.relatedTransforms = d.data.items;
            });
        }   
    }

    public stateCtrl: FormControl;
    public filteredStates: Observable<any[]>;
    public states: Array<any> = [  ];
    public errorMsg:string = null;
    public relatedTransforms: Array<string>=[];
    public isInProgress=false;

    public get details() : AddApplicationData {
        return CommandCollection.AddApplication.data;
    }

    $onInit() {
        CommandCollection.AddApplication.resetData();
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return CommandCollection.AddApplication.isDataValid();
    }

    closeDialog() {
        this.errorMsg=null;
        this.isInProgress=true;
        CommandCollection.AddApplication.apply().then((r)=> {
            this.isInProgress=false;
            if(r.status && r.status.errorCode == 0) {
                this.dialogRef.close(this.details);
                CommandCollection.AddApplication.afterApply(r.data);
            }
            else if (r.status) {
                this.errorMsg = r.status.message;
            }
        });
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}

