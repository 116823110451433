import * as CRS from '../api/CRS';
import * as IRQ from '../api/IRQ';
import { Constants } from '../api/Constants';

export class MsiRowDepExt {

    constructor(row: CRS.RSMsiRowDependency) {
        this._row = row;
    }

    private _row: CRS.RSMsiRowDependency;

    public get tableName(): string {
        return this._row.tableName;
    }
    public get children() : Array<MsiRowDepExt> {
        return this._row.children.map(x=>new MsiRowDepExt(x));
    }
    public get isDeletion(): boolean {
        return this._row.modType=="D";
    }

    public fkParentFilter(): Array<CRS.RSMsiColumnValue> {
        return this._row.row.columns.filter(x=>!x.isFkToParent);
    }

    public isPk(col: CRS.RSMsiColumnValue): boolean {
        return this._row.primaryKeyCols.indexOf(col.columnId) != -1;
    }

    public getColumnValue(columnId:number, usePlaceholderForNull:boolean) : any {
        var col = this._row.row.columns.filter(x=>x.columnId == columnId);
        return (col && col.length) 
            ? col[0].value 
            : (usePlaceholderForNull) ? Constants.NULL_PLACEHOLDER : null;
    }

    public getPrimaryKeySet() : IRQ.IRQKeyItem {
        var i:IRQ.IRQKeyItem = {
            tableName: this._row.tableName,
            primaryKeys:[],
            values:[],
            modType:this._row.modType
        };
        this._row.primaryKeyCols.forEach(pk=>{
            var v = this.getColumnValue(pk, false);
            var k : IRQ.IRQColumnValue={columnIndex:pk, value:v};
            i.primaryKeys.push(k);
        });
        if (this._row.modType == 'U') {
            this._row.row.columns.forEach(c=>{
                var v :IRQ.IRQColumnValue={columnIndex:c.columnId, value:c.value};
                i.values.push(v);
            });
        }
        return i;
    }
}