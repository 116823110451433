<div fxLayout="column">

    <div fxFlex="none" fxLayout="row" fxLayoutGap="10px">
        
        <div fxFlex>
            <h3>INTUNE INFO</h3>
            <div>
                <input-edit datatype="STRING" label="Tenant Id" [value]="project.intuneTenantId.value" context="intuneTenantId"
                            (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Client Id" [value]="project.intuneClientId.value" context="intuneClientId"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Certificate Key" [value]="project.intuneCertificatePrivateKey.value" context="intuneCertificatePrivateKey"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Credentals (Base 64 Enc)" [value]="project.intuneCredentials.value" context="intuneCredentials"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Owner" [value]="project.intuneOwner.value" context="intuneOwner"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="URL" label="Information URL" [value]="project.intuneInformationUrl.value" context="intuneInfoUrl"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Notes" [value]="project.intuneNotes.value" context="intuneNotes"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Navigator Id Element" [value]="project.intuneNavigatorIdElement.value" context="intuneNavigatorIdElement"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </div>
        <div fxFlex="25">
            <h3>AIRLOCK</h3>
            <div>
                <input-edit datatype="STRING" label="Api Key" [value]="project.airLockApiKey.value" context="airLockApiKey"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
            <div>
                <input-edit datatype="STRING" label="Server URL" [value]="project.airLockServerUrl.value" context="airLockServerUrl"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>            <div>
                <input-edit datatype="STRING" label="App Name" [value]="project.airLockApplicationName.value" context="airLockApplicationName"
                           (on-change)="infoChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </div>
        <div fxFlex>
            <h3>PUBLISHING COMMANDS</h3>
            <mat-tab-group>
                <mat-tab label="Msi">
                    <div class="sw-big-pad">
                        <input-edit datatype="MSTRING" [rows]="20" label="Publishing Commands" [value]="currentProject.publishCommandsSplit['DFLT']" 
                            (on-change)="publishingCommandsChanged($event)" [disable]="!canEdit()"></input-edit> 
                    </div>
                </mat-tab>
                <mat-tab label="EXE">
                    <div class="sw-big-pad">
                        <input-edit datatype="MSTRING" [rows]="20" label="Publishing Commands (Exe)" [value]="currentProject.publishCommandsSplit['EXE']" 
                        (on-change)="publishingCommandsExeChanged($event)" [disable]="false ||!canEdit()"></input-edit>
                    </div>  
                </mat-tab>
                <!-- <mat-tab label="App-V">
                    <div class="sw-big-pad">
                        <input-edit datatype="MSTRING" [rows]="20" label="Publishing Commands (App-V)" [value]="currentProject.publishCommandsSplit['VIRT']" 
                        (on-change)="publishingCommandsVirtChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>
                </mat-tab> -->
                <mat-tab label="MSIX">
                    <div class="sw-big-pad">
                        <input-edit datatype="MSTRING" [rows]="20" label="Publishing Commands (Msix)" [value]="currentProject.publishCommandsSplit['MSIX']" 
                            (on-change)="publishingCommandsMsixChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>
                </mat-tab>
                <mat-tab label="Liquidware">
                    <div class="sw-big-pad">
                        <input-edit datatype="MSTRING" [rows]="20" label="Publishing Commands (Liquidware)" [value]="currentProject.publishCommandsSplit['LIQD']" 
                            (on-change)="publishingCommandsLiqdChanged($event)" [disable]="!canEdit()"></input-edit>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>  
        <div fxFlex="12">
            <h3>DOCUMENTATION</h3>
            <mat-form-field appearance="outline" *ngIf="readmeDocumentationScriptsReady">
                <mat-label>Readme Script</mat-label>
                <mat-select [(ngModel)]="project.readmeDocumentationScript.value"
                    (selectionChange)="readmeDocumentationScriptChanged()" [disabled]="!canEdit()">
                    <mat-option value="{{null}}">Default Script</mat-option>
                    <mat-option [value]="r" *ngFor="let r of readmeDocumentationScripts">{{r}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>  
</div>

