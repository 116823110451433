import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { first} from 'rxjs/operators';
import { UtilService } from './svc/utilService';
import { UserCacheService } from './svc/userCacheService';
import { AppUploadService } from './svc/appUploadService';
import { AuthService } from './svc/authService';
import { CurrentProjectService } from './svc/currentProjectService';
import { StylingService } from './svc/stylingService';
import { NavigationService } from './svc/navigationService';
import { CommandCollection } from './commands/command-collection';

import * as CRS  from './api/CRS';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './shared/css/buttons.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  constructor(
    private injector:Injector,
    private stylingService : StylingService,
    public currentProject: CurrentProjectService,
    public userCacheService: UserCacheService,
    public utilService: UtilService,
    public authService: AuthService,
    public navigationService: NavigationService,
    public appUploadService: AppUploadService,
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.stylingService.onLoaded().pipe(first()).subscribe((data)=>{
      this.userCheck();
    });
    this.stylingService.ensureLoaded();
    CommandCollection.initialise(this.injector);
  }

  private userCheck() {

    if (this.authService.isLoggedIn) {
      this.userCacheService.ensureUser();
    }
    else {
      this.authService.onLoggedIn().subscribe((state)=> {
        this.userCacheService.ensureUser();
      });
    }
    this.userCacheService.onUserLoaded().subscribe(u=> {
      this.user=u;
    });

    // Need to update uploader URL after styling has given us correct url
    this.appUploadService.refresh();

    this.authService.onTimedOut().subscribe((state)=>{
      this.navigationService.goHome();
    });

  }

  ngOnDestroy(): void {
  }

  public isStylingReady() : boolean {
    return this.stylingService.isLoaded();
  }

  public isAdminUser(): boolean {
    return this.utilService.privileges?.admin;
  };

  public goErr() {
    this.navigationService.goError();
  }

  public isReady() : boolean {
    return this.user !=null;
  }

  public user : CRS.RSGetUserInformation=null;

}
