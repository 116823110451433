import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../api/Constants';
import { UtilService } from './utilService';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(
        private router : Router,
        private utilService: UtilService,
        private route: ActivatedRoute) { 
    }

    public get url() : string {
        return this.router.url;
    }

    public get urlParts() : Array<string> {
        return this.router.url.split("/");
    }

    public getCurrentRouteParameter(name:string): string {
        return this.route.params[name];
    }

    public goAdmin() : Promise<boolean> {
        return this.router.navigate([ 
            Constants.ROUTE_ADMINDASHBOARD 
        ]);
    }
    
    public goHome() : Promise<boolean> {
        return this.router.navigateByUrl("/");
    }
    
    public goError() {
        this.router.navigateByUrl("xxx");
    } 
    
    public goErrorPage() {
        this.router.navigate([Constants.ROUTE_ERROR]);
    }
    
    public goServerLostFULL() {
        this.router.navigate([Constants.ROUTE_ERROR_SERVER_LOST]);
    }

    public goAdminAssessmentGroupsFULL()  : Promise<Boolean> {
        return this.router.navigate([ 
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS
        ]);
    }
    
    public goAdminAssessmentGroupFULL(groupId:string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG,
            groupId
        ]);   
    }

    public goAdminAssessmentGroupCheckFULL(groupId:string, checkId: string) : Promise<Boolean> {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG,
            groupId,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK_SLUG,
            checkId
        ]);
    }

    public goAdminCheckLibrary(): Promise<Boolean>  {
        return this.router.navigate([
             Constants.ROUTE_ADMINDASHBOARD,
             Constants.ROUTE_ADMINCHECKLIBRARY
        ]);
    }
    
    public goAdminCheckLibraryCheckFULL(checkId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINCHECKLIBRARY,
            Constants.ROUTE_ADMINCHECKDETAIL_MAIN, 
            checkId
        ]);
    }

    public goAdminPatchGroupsFULL(): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINPATCHGROUPS
        ]);
    }

    public goAdminPatchGroupFULL(patchGroupId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINPATCHGROUPS,
            Constants.ROUTE_PATCHGROUP_MAIN,
            patchGroupId]);
    }

    public goAdminPatchGroupItemDetailFULL(remediationId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_PATCHGROUPITEMDETAIL_MAIN, 
            remediationId
        ]);
    }

    public goAdminPatchCategoryFiles(pc:string): Promise<Boolean>  {
        return this.router.navigate([Constants.ROUTE_PATCHCATEGORYFILES_MAIN, pc], {relativeTo: this.route})
    }

    public goAdminUser(userId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINUSERS,
            Constants.ROUTE_ADMINUSERDETAIL_MAIN, 
            userId]);
    }

    public navigateToRouteFULL(items: Array<string>): Promise<Boolean>  {
        return this.router.navigate(items);        
    }

    public getAppPath(projectId:string, applicationId:string): string {
        var p = this.fromProject(projectId);
        var a = this.fromApplication(applicationId);
        return `/${p[0]}/${p[1]}/${a[0]}/${a[1]}/${a[2]}`;
    } 

    public goProjectApplicationsFULL(projectId: string): Promise<Boolean>  {
        var p = this.build([
            this.fromProject(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS 
        ]);
        return this.router.navigate(p);
    }

    public goProjectApplicationDetailsFULL(projectId: string, applicationId: string) : Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            this.fromApplication(applicationId)
        ]);
        return this.router.navigate(p);
    }

    public goProjectSubFULL(projectId: string, category:string) : Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            category
        ]);
        return this.router.navigate(p);
    }

    public goProjectSettingsFULL(projectId: string, category: string) : Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            Constants.ROUTE_PROJECTSETTINGS,
            category
        ]);
        return this.router.navigate(p);
    }

    public goProjectReportAssessmentGroupFULL(projectId: string, agId: string) : Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            this.fromAg(agId)
        ]);
        return this.router.navigate(p);
    }

    public goProjectReportsCheckDetailFULL(projectId: string, agId:string, checkId:string) : Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            this.fromAg(agId),
            Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN, 
            checkId
        ]);
        return this.router.navigate(p);
    }

    public goProjectActionJobFULL(projectId:string, jobId:string) {
        var p = this.build([
            this.fromProject(projectId),
            Constants.ROUTE_PROJECTACTIONS,
            Constants.ROUTE_PROJECTACTIONDETAILS_MAIN, 
            this.compressGuid(jobId)
        ])
        this.router.navigate(p);
    }

    public goProjectDetailsFULL(projectId: string): Promise<Boolean>  {
        var p = this.build([this.fromProject(projectId)]);
        return this.router.navigate(p);
    }

    public goProjectQueriesFULL(projectId:string, category?: string) {
        var qryRoute = category??Constants.ROUTE_PROJECTQUERIESSTANDARDS;
        var p = this.build([
            this.fromProject(projectId),
            Constants.ROUTE_PROJECTQUERIES,
            qryRoute
        ])
        this.router.navigate(p);
    }

    public goProjectCreate(serverId?:string): Promise<Boolean>  {
        var svrArg = (serverId)?"?svr="+serverId: "";
        return this.router.navigateByUrl("/" + Constants.ROUTE_PROJECTCREATE + svrArg);
    }

    public goApplicationJobFULL(projectId:string, applicationId:string, jobId: string) : Promise<boolean> {

        var taskStr = (this.utilService.isGuid(jobId))
                ? this.compressGuid(jobId)
                : jobId;

        var p = this.build([
            this.fromProject(projectId),
            this.fromApplication(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN,
            taskStr
        ]);        
        return this.router.navigate(p);
    }

    public goApplicationBrowseTableFULL(projectId: string, applicationId: string, tableName:string): Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            this.fromApplication(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONBROWSE,
            Constants.ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN,
            tableName
        ]);
        return this.router.navigate(p);
    }

    public goApplicationBrowseFULL(projectId: string, applicationId: string): Promise<boolean> {
        var p = this.build([
            this.fromProject(projectId),
            this.fromApplication(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONBROWSE
        ]);
        return this.router.navigate( p);
    }

    private build(items: Array<any>) : Array<string> {
        var ret:Array<string>=[];
        items.forEach(i=>{
            if (Array.isArray(i)) {
                i.forEach(ai=>ret.push(ai));
            }
            else {
                ret.push(i);
            }
        });
        return ret;
    }

    private fromProject( projectId:string): Array<string> {
        return [ Constants.ROUTE_PROJECTDETAILS_MAIN,this.compressGuidWithSvr(projectId) ];
    }

    private fromApplication( applicationId: string) : Array<string> {
        return [ 
            Constants.ROUTE_PROJECTAPPLICATIONS,
            Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN,
            this.compressGuid(applicationId)
        ];
    }

    private fromAg(agId:string): Array<string> {
        return [
            Constants.ROUTE_PROJECTREPORTS,
            Constants.ROUTE_PROJECTREPORTAG_MAIN, 
            agId
        ];
    }

    private compressGuid(g:string): string {
        if(this.utilService.isEmpty(g))
            return g;
        let re = /-/gi;
        return g.replace(re, "");
    }

    private compressGuidWithSvr(g:string): string {
        if(this.utilService.isEmpty(g))
            return g;
        let re = /-/gi;
        return g.replace(re, "") + `@${this.utilService.serverId}`;
    }

    public static expandGuid(id:string) :string {
        if (id && NavigationService.isGuid(id)) {
            return id;
        }
        else if (id) {
            var reg = new RegExp(/^([a-f0-9]{8})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{12})$/);
            var m = id.match(reg);
            if (m)
                return `${m[1]}-${m[2]}-${m[3]}-${m[4]}-${m[5]}`;
        }
        return id;
    }

    private static isGuid(g:string) :boolean {
        var isEmpty = g === undefined || g == null || g.trim() == ''
        if (isEmpty)
            return false;
        return g.toLocaleLowerCase().match("^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$")!=null;
    }

}
