import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MsiRowDepExt } from 'src/app/model/MSIROWDEPEXT';

import * as CRS from '../../api/CRS';
import * as IRQ from '../../api/IRQ';

@Component({
    selector: 'application-browse-row-confirmation-dialog',
    templateUrl: './applicationBrowseRowConfirmation.html',
    styleUrls: [ './applicationBrowseRowConfirmation.scss']
})
export class ApplicationBrowseRowConfirmationDialog implements OnInit {

    constructor(
        private currentApplication:CurrentApplicationService,
        private dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    public tableName:string;
    public rows: Array<CRS.RSMsiRow>;
    public treeControl :NestedTreeControl<MsiRowDepExt>;
    public dataSource: MatTreeNestedDataSource<MsiRowDepExt>;

    // WFD=Waiting for dependencies
    // DDR=Data dependencies ready
    // DEL=Deleting
    private state: string = "WFD";
 
    ngOnInit() {
        this.treeControl = new NestedTreeControl<MsiRowDepExt>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<MsiRowDepExt>();
        this.tableName = this.data.tableName;
        this.rows = this.data.rows;
        this.currentApplication.browser.getRowDependencies(this.tableName,this.rows).then((r)=>{
            var extItems = r.data.items.map(x=>new MsiRowDepExt(x));
            this.dataSource.data= extItems;
            extItems.forEach(d=>this.treeControl.expand(d));
            this.state = "DDR";
        });
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return this.isDependencyDataReady();
    }

    public childrenAccessor = (node: CRS.RSMsiRowDependency) => node.children ?? [];

    public hasChild = (_: number, node: CRS.RSMsiRowDependency) =>{
        return !!node.children && node.children.length > 0;
    }

    public isWaitingForDependencies() : boolean {
        return this.state == "WFD";
    }
    public isDependencyDataReady(): boolean {
        return this.state == "DDR";
    }
    public isDeleting(): boolean {
        return this.state == "DEL";
    }

    public apply() : void {
        this.state = "DEL";
        var data = <Array<MsiRowDepExt>>this.dataSource.data;
        var keys= this.extractKeys(data);
        this.currentApplication.updatePackageKeySet(keys).then((r)=>{
            this.dialogRef.close(true);
        });
    }

    private extractKeys(depRoots: Array<MsiRowDepExt>) : Array<IRQ.IRQKeyItem> {
        var ret = [];
        depRoots.forEach(d=> {
            ret.push(d.getPrimaryKeySet());
            this.extractKeys(d.children).forEach(x=>ret.push(x));
        });
        return ret;
    }

    cancelDialog() {
        this.dialogRef.close(null);
    }
}

