import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { NavigationService } from 'src/app/svc/navigationService';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-application-related-apps',
  templateUrl: './project-application-related-apps.html',
})
export class ProjectApplicationRelatedApps implements OnInit, OnDestroy {

  constructor(
      private currentProject: CurrentProjectService,
      private currentApplication: CurrentApplicationService, 
      private navigation: NavigationService
  ) { }

  private subscriptions : Array<Subscription> = new Array<Subscription>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  public get application() : CRS.RSGetApplication {
    return this.currentApplication.dataItem;
  }

  public isDataReady() : boolean {
    return this.currentProject.dataContext.isReady && this.currentApplication.dataContext.isReady;
  }

  public hasSome() : boolean {
    return this.isDataReady() && this.currentApplication.dataItem.extractedApplications.length>0;
  }

  public getAppLink(id:string): string {
    return this.navigation.getAppPath(this.currentApplication.dataItem.projectId, id);
  }

}
